import { parseBoolWizards } from './boolManipulation';
import { isValidHttpUrl } from './urlManipulation';
import { domain, consentKey, calcOperators, titleDropdownOptions } from '../variables';
import { flattenObjectArray, getRef } from './objectManipulation';
import { toTitleCase } from './stringManipulation';
import routes from '../variables/routes';
import routerHistory from '../../history';
import { removeSessionStorage } from '../../storage/sessionStorage';

export const promiseTimeout = (ms, data) => {
  return new Promise(resolve => setTimeout(() => resolve(data), ms));
};

export function postIframe(postObj) {
  if (typeof window !== 'undefined') {
    window.parent.postMessage(postObj, '*');
    // eslint-disable-next-line no-undef
    // const myEvent = new CustomEvent('message', postObj); // my_iframe_event
    // window.parent.dispatchEvent(myEvent, '*');
    // console.log('Sync Data sent to iframe');
  }
}

export const formatImageUrlFromStrapi = _cover => {
  const cover = _cover;
  // // console.log('url: ', cover);
  if (isValidHttpUrl(cover?.url)) return cover;
  if (cover?.provider !== 'local') return cover;
  cover.url = `${domain.strapi}${cover?.url}`;
  // // console.log('newUrl: ', cover?.url);
  return cover;
};

export const translateFromStrapi = (links, key, locale) => {
  if (!links || links.length === 0) return null;
  const newLinks = links.map(_x => {
    const x = { ..._x };
    const newValue = x[`${key}_${locale}`] || x[key];
    x[key] = newValue;
    return x;
  });

  return newLinks;
};

export const transformConditionalValue = (_conditionalValue, fieldType) => {
  let conditionalValue = _conditionalValue;
  if (fieldType === 'string') {
    conditionalValue = conditionalValue?.toString() || '';
  } else if (fieldType === 'boolean') {
    conditionalValue = parseBoolWizards(conditionalValue, fieldType);
  } else if (fieldType === 'number') {
    conditionalValue = parseFloat(conditionalValue) || 0;
    // console.log('conditionalValue: ', conditionalValue);
  }
  return conditionalValue;
};

export const getTransformPeriod = _input => {
  let input = _input;
  if (input === 'Monthly') {
    input = 'M';
  } else if (input === 'Quarterly') {
    input = 'Q';
  } else if (input === 'SemiAnnual') {
    input = 'SA';
  } else {
    input = 'A';
  }

  return input;
};

export const transformConditionalStatus = (
  conditionalValue,
  operator,
  _storeConditionalKeyValue,
  fieldType,
  arrayKey,
) => {
  let bool = true;

  if (operator === 'equal') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // console.log('storeConditionalKey: ', storeConditionalKey);
    bool = conditionalValue === storeConditionalKey;
  } else if (operator === 'notEqual') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // console.table({ storeConditionalKey, conditionalValue });
    bool = conditionalValue !== storeConditionalKey;
  } else if (operator === 'notEqualAndValid') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // // console.log('storeConditionalKey: ', storeConditionalKey);
    // // console.log('conditionalValue: ', conditionalValue);
    bool =
      // storeConditionalKey &&
      conditionalValue &&
      // storeConditionalKey.toString().length !== 0 &&
      conditionalValue.toString().length !== 0 &&
      conditionalValue !== storeConditionalKey;
  } else if (operator === 'notEqualOrNotValid') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool =
      //! storeConditionalKey ||
      !conditionalValue ||
      // storeConditionalKey.toString().length === 0 ||
      conditionalValue.toString().length === 0 ||
      conditionalValue !== storeConditionalKey;
  } else if (operator === 'greaterThan') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = storeConditionalKey > conditionalValue;
  } else if (operator === 'greaterThanAndEqualTo') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // console.log(storeConditionalKey);
    // console.log(conditionalValue);
    bool = !!conditionalValue && storeConditionalKey >= conditionalValue;
  } else if (operator === 'lessThanAndEqualTo') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = storeConditionalKey <= conditionalValue;
  } else if (operator === 'lessThan') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = storeConditionalKey < conditionalValue;
  } else if (operator === 'valid') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = !!storeConditionalKey;
  } else if (operator === 'notValid') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = !storeConditionalKey;
  } else if (operator === 'includedInArray') {
    const tempValue = _storeConditionalKeyValue?.find(
      x => transformConditionalValue(x[arrayKey], fieldType) === conditionalValue,
    );
    // const storeConditionalKey = transformConditionalValue(_storeConditionalKeyValue, fieldType);
    bool = !!tempValue;
  } else if (operator === 'includes') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // const storeConditionalKey = transf  ormConditionalValue(_storeConditionalKeyValue, fieldType);
    bool = storeConditionalKey?.includes(conditionalValue);
  } else if (operator === 'notIncludes') {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    // const storeConditionalKey = transformConditionalValue(_storeConditionalKeyValue, fieldType);
    bool = !storeConditionalKey?.includes(conditionalValue) && !!storeConditionalKey;
  } else {
    const storeConditionalKey = transformConditionalValue(
      _storeConditionalKeyValue,
      fieldType,
    );
    bool = conditionalValue === storeConditionalKey;
  }

  return bool;
};

export const generateSearchWords = object => {
  const initialArray = [];
  const flattenArray = flattenObjectArray(object, initialArray);
  // // console.log('flattenArray: ', flattenArray);
  // const myArray = flattenArray.map((str, index) => ({ value: str, id: index + 1 }));
  return flattenArray;
};

export const getLocalStorageSize = () => {
  let total = 0;
  for (const x in localStorage) {
    // Value is multiplied by 2 due to data being stored in `utf-16` format, which requires twice the space.
    const amount = localStorage[x].length * 2; /// 1024 / 1024
    if (!isNaN(amount) && localStorage.hasOwnProperty(x)) {
      // // console.log(x, localStorage.getItem(x), amount);
      total += amount;
    }
  }
  return total.toFixed(2);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
export const storageEstimateWrapper = () => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    // We've got the real thing! Return its response.
    return navigator.storage.estimate();
  }

  if (
    'webkitTemporaryStorage' in navigator &&
    'queryUsageAndQuota' in navigator.webkitTemporaryStorage
  ) {
    // Return a promise-based wrapper that will follow the expected interface.
    return new Promise((resolve, reject) => {
      navigator.webkitTemporaryStorage.queryUsageAndQuota((usage, quota) => {
        resolve({ usage, quota });
      }, reject);
    });
  }

  // If we can't estimate the values, return a Promise that resolves with NaN.
  return Promise.resolve({ usage: NaN, quota: NaN });
};

export const jsonFileSize = obj => {
  // returns object size in bytes
  return encodeURI(JSON.stringify(obj)).split(/%..|./).length - 1;
};

// Get duration (in s) before (cache) expiration from headers of a fetch
// request.
export const getExpiresFromHeaders = headers => {
  // Try to use the Cache-Control header (and max-age)
  if (headers.get('cache-control')) {
    const maxAge = headers.get('cache-control').match(/max-age=(\d+)/);
    return parseInt(maxAge ? maxAge[1] : 0, 10);
  }

  // Otherwise try to get expiration duration from the Expires header
  if (headers.get('expires')) {
    return (
      parseInt(new Date(headers.get('expires')).getTime() / 1000, 10) -
      new Date().getTime()
    );
  }
  return null;
};

export const cacheAssets = assets => {
  return new Promise(function(resolve, reject) {
    // open cache
    caches
      .open('assets')
      .then(cache => {
        // the API does all the magic for us
        cache
          .addAll(assets)
          .then(() => {
            // console.log('all assets added to cache');
            resolve();
          })
          .catch(err => {
            // console.log('error when syncing assets', err);
            reject();
          });
      })
      .catch(err => {
        // console.log('error when opening cache', err);
        reject();
      });
  });
};

export const isEmail = username => {
  return /\S+@\S+\.\S+/.test(username);
};

export const formatUsername = userName => {
  if (isEmail(userName)) return userName;
  let result = userName;
  // clean mobile number
  const mobileNum = userName?.replace(/[^0-9]/g, '');

  if (mobileNum?.length === 11) {
    const mobileSearchParam = mobileNum?.substring(1, 11);
    result = mobileSearchParam;
  } else if (mobileNum?.length === 12) {
    const mobileSearchParam = mobileNum?.substring(1, 12);
    result = mobileSearchParam; // returns null if nothing found.
  } else if (mobileNum?.length === 10) {
    result = mobileNum;
  } else if (mobileNum?.length === 7) {
    const numberSearch = mobileNum;
    result = numberSearch?.length === 1 ? numberSearch[0] : null; // if more than one number comes up, return null
  }

  return result;
};

/*
// all urls will be added to cache
var assets = []; // list of urls to be cached

// cache responses of provided urls
cacheAssets(assets)
  .then(() => {
      // console.log('All assets cached')
  });
  */

export const rangeOfYears = (start, end) =>
  Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

export const getYearsFromRange = settings => {
  const { pastYears = 0, futureYears = 0 } = settings || {};
  // console.log('futureYears: ', futureYears);
  // console.log('pastYears: ', pastYears);

  const initialYears = rangeOfYears(
    new Date().getFullYear() - pastYears,
    new Date().getFullYear() + futureYears,
  );
  const newYears = initialYears
    .sort()
    .reverse()
    .map(year => ({ value: year, title: year }));
  return newYears;
};
export const syntaxHighlight = json => {
  if (typeof json !== 'string') {
    json = JSON.stringify(json, undefined, 2);
  }
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function(match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return `<span class="${cls}">${match}</span>`;
    },
  );
};

export const isMobileSafari = () => {
  return (
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/)
  );
};

export const isIOSSafari = () => {
  return (
    /iP(ad|od|hone)/i.test(navigator.userAgent) &&
    /WebKit/i.test(navigator.userAgent) &&
    !/(CriOS|FxiOS|OPiOS|mercury)/i.test(navigator.userAgent)
  );
};

export const isIOS = () => {
  const { userAgent } = window.navigator;
  // // console.log('ua: ', userAgent);
  const isIPad = !!userAgent.match(/iPad/i);
  const isIPhone = !!userAgent.match(/iPhone/i);

  return isIPad || isIPhone;
};

export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    const versions = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    return versions[0] || null;
  }
  return null;
};

export const isSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iP(ad|od|hone)/i);
  const hasSafariInUa = !!ua.match(/Safari/i);
  const noOtherBrowsersInUa = !ua.match(/Chrome|CriOS|OPiOS|mercury|FxiOS|Firefox/i);
  let result = false;
  if (iOS) {
    // detecting Safari in IOS mobile browsers
    const webkit = !!ua.match(/WebKit/i);
    result = webkit && hasSafariInUa && noOtherBrowsersInUa;
  } else if (window.safari !== undefined) {
    // detecting Safari in Desktop Browsers
    result = true;
  } else {
    // detecting Safari in other platforms
    result = hasSafariInUa && noOtherBrowsersInUa;
  }
  return result;
};

export const isFirefox = () => {
  return navigator.userAgent.toLowerCase().includes('firefox');
};

export const checkOnlineStatus = async () => {
  // For demo purpose you can use the favicon from MSN website
  // Also notice the appended date param which helps skip browser caching.
  try {
    const online = await fetch(
      `https://static-global-s-msn-com.akamaized.net/hp-neu/sc/2b/a5ea21.ico?d=${Date.now()}`,
    );
    return online.status >= 200 && online.status < 300; // either true or false
  } catch (err) {
    return false; // definitely offline
  }
};

export const mobileAndTabletCheck = () => {
  let check = false;
  (function(a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const removeNonWizardRoute = router => {
  const historyEntries = routerHistory; // router?.history;
  // console.log('historyEntries: ', historyEntries);
  if (historyEntries && historyEntries.length > 0) {
    const histLen = historyEntries.length;
    for (let i = 1; i <= histLen; i += 1) {
      const currentRecord = historyEntries[i];
      if (currentRecord?.startsWith(routes.wizards.index)) {
        historyEntries.pop();
      }
    }
  }
  const historyEntries2 = router?.history?.entries;
  // console.log('historyEntries2: ', historyEntries2);
};

export const deleteCookiesAndStorageExceptConsent = () => {
  if (typeof window !== 'undefined') {
    localStorage.clear();

    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (name !== consentKey) {
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
    }
  }
};

export const getExpirationTime = dateFutureStr => {
  const dateFuture = new Date(dateFutureStr);
  const dateNow = new Date();
  if (dateFuture < dateNow) return '';
  let remainingSeconds = Math.abs(dateFuture - dateNow) / 1000;
  const days = Math.floor(remainingSeconds / 86400);
  remainingSeconds -= days * 86400;
  const hours = Math.floor(remainingSeconds / 3600) % 24;
  remainingSeconds -= hours * 3600;
  const minutes = Math.floor(remainingSeconds / 60) % 60;
  remainingSeconds -= minutes * 60;
  const seconds = Math.floor(remainingSeconds);
  let difference = '';
  if (days > 0) {
    difference += `${days}d `;
  }
  if (hours > 0) {
    difference += `${hours}h `;
  }
  if (minutes > 0) {
    difference += `${minutes}m `;
  }
  difference += `${seconds}s`;
  return difference;
};

export const evaluateExpressions = (storeState, expressions) => {
  let res = '';
  let str = '';
  for (let i = 0; i < expressions.length; i++) {
    if (expressions[i].operateOn === 'ArrayOfObjects') {
      const arrOfObj = getRef(storeState, expressions[i].key) || [];
      for (let j = 0; j < arrOfObj.length; j++) {
        if (res.length === 0) {
          res = arrOfObj[j][expressions[i].name];
          // console.log('res: ', res);
        } else {
          let exp = '';
          if (expressions[i].operator) {
            exp += calcOperators[expressions[i].operator];
          }
          exp += `${arrOfObj[j][expressions[i].name]}`;
          // console.log('exp1: ', exp);

          // exp = `${res}${exp}`;
          // console.log('exp2: ', exp);

          res += exp;
          // console.log('res: ', res);
        }
      }
      res = eval(res);
    } else if (expressions[i].operateOn === 'individual') {
      str += getRef(storeState, expressions[i].key);
      if (expressions[i].operator && i < expressions.length - 1) {
        str += calcOperators[expressions[i].operator];
      }
      try {
        res += eval(str);
      } catch {
        res = '';
      }
    }
  }
  return res;
};

export const formatTitleToDropDownOptions = title => {
  if (!title) {
    return '';
  }
  if (titleDropdownOptions.includes(title?.toLowerCase())) {
    return title;
  }

  let res = title;
  titleDropdownOptions.map((option, index) => {
    if (option?.toLowerCase() === title?.toLowerCase()) {
      res = option;
    }
    if (option.split('.')[0].toLowerCase() === title.toLowerCase()) {
      res = option;
    }
  });
  return res;
};

export const getObjectIndexFromArray = (arr, key, keyValue) => {
  let res;
  arr.map((obj, index) => {
    if (obj[key] === keyValue) {
      res = index;
    }
  });
  return res;
};

export const getEnv = () => {
  const env = domain?.env;
  if (env === 'development') {
    return 'dev';
  }
  if (env === 'staging') {
    return 'staging';
  }
  if (env === 'production') {
    return 'prod';
  }
  return 'dev';
};

export const getStatusIndicator = status => {
  let statusObject = {
    icon: 'done',
    color: 'success',
  };
  switch (status) {
    case 'Active':
      statusObject = {
        icon: 'done',
        color: 'success',
      };
      break;
    case 'Inactive':
      statusObject = {
        icon: 'priority_high',
        color: 'error',
      };
      break;
    case 'Matured':
      statusObject = {
        icon: 'priority_high',
        color: 'warning',
      };
      break;
    case 'Due':
      statusObject = {
        icon: 'report_problem',
        color: 'warning',
      };
      break;
    case 'No Premiums Due':
      statusObject = {
        icon: 'done_all',
        color: 'neutral',
      };
      break;
    case 'No Premiums Due - Paid Up':
      statusObject = {
        icon: 'done_all',
        color: 'success',
      };
      break;
    case 'Not Ready to Renew':
      statusObject = {
        icon: 'done',
        color: 'neutral',
      };
      break;
    case 'Not Ready to Renew - Under Review':
      statusObject = {
        icon: 'announcement',
        color: 'neutral',
      };
      break;
    case 'Cancelled':
      statusObject = {
        icon: 'announcement',
        color: 'error',
      };
      break;
    case 'Changes in progress':
      statusObject = {
        icon: 'announcement',
        color: 'warning',
      };
      break;

    case 'Recurring Payment Enabled':
      statusObject = {
        icon: 'auto_mode',
        color: 'neutral',
        link: routes.cart.autoPayCenter,
      };
      break;

    case 'Recurring Payment Active':
      statusObject = {
        icon: 'auto_mode',
        color: 'success',
        link: routes.cart.autoPayCenter,
      };
      break;

    case 'Recurring Payment Paused':
      statusObject = {
        icon: 'auto_mode',
        color: 'warning',
        link: routes.cart.autoPayCenter,
      };
      break;

    case 'Recurring Payment Failed':
      statusObject = {
        icon: 'auto_mode',
        color: 'error',
        link: routes.cart.autoPayCenter,
      };
      break;

    case 'Added To Cart':
      statusObject = {
        icon: 'shopping_cart',
        color: 'neutral',
        link: routes.cart.index,
      };
      break;
    case 'Expired':
      statusObject = {
        icon: 'priority_high',
        color: 'error',
      };
      break;
    case 'First Premium Paid-Under Review':
      statusObject = {
        icon: 'announcement',
        color: 'neutral',
      };
      break;
    case 'First Premium Payment Needed':
      statusObject = {
        icon: 'report_problem',
        color: 'warning',
      };
      break;
    case 'O/S Premium-Payment Needed':
      statusObject = {
        icon: 'report_problem',
        color: 'warning',
      };
      break;
    case 'Ready to Renew':
      statusObject = {
        icon: 'report_problem',
        color: 'warning',
      };

      break;

    case 'Completed':
      statusObject = {
        icon: 'done',
        color: 'success',
      };
      break;

    case 'Processing':
      statusObject = {
        icon: 'sync',
        color: 'warning',
      };
      break;

    case 'Rejected':
      statusObject = {
        icon: 'report_problem',
        color: 'error',
      };
      break;

    case 'Submitted':
      statusObject = {
        icon: 'announcement',
        color: 'info',
      };
      break;
    case 'Draft':
      statusObject = {
        icon: 'rotate_left',
        color: 'neutral',
      };
      break;
    default:
      statusObject = {
        icon: 'priority_high',
        color: 'warning',
      };
      break;
  }

  statusObject.status = status;
  return statusObject;
};

export const addAlpha = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

export const formatMode = mode => {
  const frq = mode?.toLowerCase();
  const mapping = {
    annually: 'Annual',
    annual: 'Annual',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    semiannually: 'Semi-Annual',
    'semi-annual': 'Semi-Annual',
    'semi annual': 'Semi-Annual',
  };
  return mapping[frq];
};

export const formatModeNoun = mode => {
  const frq = mode?.toLowerCase();
  const mapping = {
    annually: 'Year',
    annual: 'Year',
    monthly: 'Month',
    quarterly: 'Quarter',
    semiannually: 'Half Year',
    'semi-annual': 'Half Year',
    'semi annual': 'Half Year',
  };
  return mapping[frq];
};

export const getButtonColorFromVariant = (variant, important = false) => {
  let buttonColor = 'secondaryButton';
  if (variant === 'text') {
    buttonColor = 'tertiaryButton';
  }
  if (variant === 'outlined') {
    buttonColor = 'secondaryButton';
  }
  if (variant === 'contained') {
    buttonColor = 'primaryButton';
  }
  if (variant === 'contained' && important) {
    buttonColor = 'importantButton';
  }
  return buttonColor;
};

export const formatAlpha2 = initialAlpha2 => {
  let alpha2 = initialAlpha2;
  if (alpha2 === 'JJ') {
    alpha2 = 'JM';
  }

  return alpha2;
};

export const unFormatAlpha2 = initialAlpha2 => {
  let alpha2 = initialAlpha2;
  if (alpha2 === 'JM') {
    alpha2 = 'JJ';
  }

  return alpha2;
};

export const isPwa = () => {
  return ['fullscreen', 'standalone', 'minimal-ui'].some(
    displayMode => window.matchMedia(`(display-mode: ${displayMode})`).matches,
  );
};

export const getAppMode = () => {
  let displayMode = 'browser';
  if (navigator.standalone || isPwa() || document.referrer.includes('android-app://')) {
    displayMode = 'standalone';
  }

  return displayMode;
};

export const getNationalityOptions = () => {
  return [
    {
      name: 'Dutch',
      value: 'NL',
    },
    {
      name: 'Afghan',
      value: 'AF',
    },
    {
      name: 'Albanian',
      value: 'AL',
    },
    {
      name: 'Algerian',
      value: 'DZ',
    },
    {
      name: 'American Samoan',
      value: 'AS',
    },
    {
      name: 'Andorran',
      value: 'AD',
    },
    {
      name: 'Angolan',
      value: 'AO',
    },
    {
      name: 'Anguillian',
      value: 'AI',
    },
    {
      name: 'Antarctican',
      value: 'AQ',
    },
    {
      name: 'Antiguan, Barbudan',
      value: 'AG',
    },
    {
      name: 'Argentine',
      value: 'AR',
    },
    {
      name: 'Armenian',
      value: 'AM',
    },
    {
      name: 'Australian',
      value: 'AU',
    },
    {
      name: 'Austrian',
      value: 'AT',
    },
    {
      name: 'Azerbaijani',
      value: 'AZ',
    },
    {
      name: 'Bahamian',
      value: 'BS',
    },
    {
      name: 'Bahraini',
      value: 'BH',
    },
    {
      name: 'Bangladeshi',
      value: 'BD',
    },
    {
      name: 'Barbadian',
      value: 'BB',
    },
    {
      name: 'Belarusian',
      value: 'BY',
    },
    {
      name: 'Belgian',
      value: 'BE',
    },
    {
      name: 'Belizean',
      value: 'BZ',
    },
    {
      name: 'Beninese',
      value: 'BJ',
    },
    {
      name: 'Bermudian',
      value: 'BM',
    },
    {
      name: 'Bhutanese',
      value: 'BT',
    },
    {
      name: 'Bolivian',
      value: 'BO',
    },
    {
      name: 'Bosnian, Herzegovinian',
      value: 'BA',
    },
    {
      name: 'Motswana',
      value: 'BW',
    },
    {
      name: 'Brazilian',
      value: 'BR',
    },
    {
      name: 'Bruneian',
      value: 'BN',
    },
    {
      name: 'Bulgarian',
      value: 'BG',
    },
    {
      name: 'Burkinabe',
      value: 'BF',
    },
    {
      name: 'Burundian',
      value: 'BI',
    },
    {
      name: 'Cambodian',
      value: 'KH',
    },
    {
      name: 'Cameroonian',
      value: 'CM',
    },
    {
      name: 'Canadian',
      value: 'CA',
    },
    {
      name: 'Cape Verdian',
      value: 'CV',
    },
    {
      name: 'Caymanian',
      value: 'KY',
    },
    {
      name: 'Central African',
      value: 'CF',
    },
    {
      name: 'Chadian',
      value: 'TD',
    },
    {
      name: 'Chilean',
      value: 'CL',
    },
    {
      name: 'Chinese',
      value: 'CN',
    },
    {
      name: 'Christmas Islander',
      value: 'CX',
    },
    {
      name: 'Cocos Islander',
      value: 'CC',
    },
    {
      name: 'Colombian',
      value: 'CO',
    },
    {
      name: 'Comoran',
      value: 'KM',
    },
    {
      name: 'Cook Islander',
      value: 'CK',
    },
    {
      name: 'Costa Rican',
      value: 'CR',
    },
    {
      name: 'Croatian',
      value: 'HR',
    },
    {
      name: 'Cuban',
      value: 'CU',
    },
    {
      name: 'Cypriot',
      value: 'CY',
    },
    {
      name: 'Czech',
      value: 'CZ',
    },
    {
      name: 'Congolese',
      value: 'CD',
    },
    {
      name: 'Danish',
      value: 'DK',
    },
    {
      name: 'Djibouti',
      value: 'DJ',
    },
    {
      name: 'Dominican',
      value: 'DO',
    },
    {
      name: 'Ecuadorean',
      value: 'EC',
    },
    {
      name: 'Egyptian',
      value: 'EG',
    },
    {
      name: 'Salvadoran',
      value: 'SV',
    },
    {
      name: 'Equatorial Guinean',
      value: 'GQ',
    },
    {
      name: 'Eritrean',
      value: 'ER',
    },
    {
      name: 'Estonian',
      value: 'EE',
    },
    {
      name: 'Swazi',
      value: 'SZ',
    },
    {
      name: 'Ethiopian',
      value: 'ET',
    },
    {
      name: 'Falkland Islander',
      value: 'FK',
    },
    {
      name: 'Faroese',
      value: 'FO',
    },
    {
      name: 'Fijian',
      value: 'FJ',
    },
    {
      name: 'Finnish',
      value: 'FI',
    },
    {
      name: 'French',
      value: 'FR',
    },
    {
      name: 'Guianan',
      value: 'GF',
    },
    {
      name: 'French Polynesian',
      value: 'PF',
    },
    {
      name: 'Gabonese',
      value: 'GA',
    },
    {
      name: 'Gambian',
      value: 'GM',
    },
    {
      name: 'Georgian',
      value: 'GE',
    },
    {
      name: 'German',
      value: 'DE',
    },
    {
      name: 'Ghanaian',
      value: 'GH',
    },
    {
      name: 'Gibraltar',
      value: 'GI',
    },
    {
      name: 'Greek',
      value: 'GR',
    },
    {
      name: 'Greenlandic',
      value: 'GL',
    },
    {
      name: 'Grenadian',
      value: 'GD',
    },
    {
      name: 'Guadeloupian',
      value: 'GP',
    },
    {
      name: 'Guamanian',
      value: 'GU',
    },
    {
      name: 'Guatemalan',
      value: 'GT',
    },
    {
      name: 'Channel Islander',
      value: 'GG',
    },
    {
      name: 'Guinean',
      value: 'GN',
    },
    {
      name: 'Guinea-Bissauan',
      value: 'GW',
    },
    {
      name: 'Guyanese',
      value: 'GY',
    },
    {
      name: 'Haitian',
      value: 'HT',
    },
    {
      name: 'Heard and McDonald Islander',
      value: 'HM',
    },
    {
      name: 'Honduran',
      value: 'HN',
    },
    {
      name: 'Hong Konger',
      value: 'HK',
    },
    {
      name: 'Hungarian',
      value: 'HU',
    },
    {
      name: 'Icelander',
      value: 'IS',
    },
    {
      name: 'Indian',
      value: 'IN',
    },
    {
      name: 'Indonesian',
      value: 'ID',
    },
    {
      name: 'Iranian',
      value: 'IR',
    },
    {
      name: 'Iraqi',
      value: 'IQ',
    },
    {
      name: 'Irish',
      value: 'IE',
    },
    {
      name: 'Manx',
      value: 'IM',
    },
    {
      name: 'Israeli',
      value: 'IL',
    },
    {
      name: 'Italian',
      value: 'IT',
    },
    {
      name: 'Ivorian',
      value: 'CI',
    },
    {
      name: 'Jamaican',
      value: 'JM',
    },
    {
      name: 'Japanese',
      value: 'JP',
    },
    {
      name: 'Jordanian',
      value: 'JO',
    },
    {
      name: 'Kazakhstani',
      value: 'KZ',
    },
    {
      name: 'Kenyan',
      value: 'KE',
    },
    {
      name: 'I-Kiribati',
      value: 'KI',
    },
    {
      name: 'Kosovar',
      value: 'XK',
    },
    {
      name: 'Kuwaiti',
      value: 'KW',
    },
    {
      name: 'Kirghiz',
      value: 'KG',
    },
    {
      name: 'Laotian',
      value: 'LA',
    },
    {
      name: 'Latvian',
      value: 'LV',
    },
    {
      name: 'Lebanese',
      value: 'LB',
    },
    {
      name: 'Mosotho',
      value: 'LS',
    },
    {
      name: 'Liberian',
      value: 'LR',
    },
    {
      name: 'Libyan',
      value: 'LY',
    },
    {
      name: 'Liechtensteiner',
      value: 'LI',
    },
    {
      name: 'Lithuanian',
      value: 'LT',
    },
    {
      name: 'Luxembourger',
      value: 'LU',
    },
    {
      name: 'Macanese',
      value: 'MO',
    },
    {
      name: 'Malagasy',
      value: 'MG',
    },
    {
      name: 'Malawian',
      value: 'MW',
    },
    {
      name: 'Malaysian',
      value: 'MY',
    },
    {
      name: 'Maldivan',
      value: 'MV',
    },
    {
      name: 'Malian',
      value: 'ML',
    },
    {
      name: 'Maltese',
      value: 'MT',
    },
    {
      name: 'Marshallese',
      value: 'MH',
    },
    {
      name: 'Mauritanian',
      value: 'MR',
    },
    {
      name: 'Mauritian',
      value: 'MU',
    },
    {
      name: 'Mahoran',
      value: 'YT',
    },
    {
      name: 'Mexican',
      value: 'MX',
    },
    {
      name: 'Micronesian',
      value: 'FM',
    },
    {
      name: 'Moldovan',
      value: 'MD',
    },
    {
      name: 'Monegasque',
      value: 'MC',
    },
    {
      name: 'Mongolian',
      value: 'MN',
    },
    {
      name: 'Montenegrin',
      value: 'ME',
    },
    {
      name: 'Montserratian',
      value: 'MS',
    },
    {
      name: 'Moroccan',
      value: 'MA',
    },
    {
      name: 'Mozambican',
      value: 'MZ',
    },
    {
      name: 'Burmese',
      value: 'MM',
    },
    {
      name: 'Namibian',
      value: 'NA',
    },
    {
      name: 'Nauruan',
      value: 'NR',
    },
    {
      name: 'Nepalese',
      value: 'NP',
    },
    {
      name: 'New Caledonian',
      value: 'NC',
    },
    {
      name: 'New Zealander',
      value: 'NZ',
    },
    {
      name: 'Nicaraguan',
      value: 'NI',
    },
    {
      name: 'Nigerien',
      value: 'NE',
    },
    {
      name: 'Nigerian',
      value: 'NG',
    },
    {
      name: 'Niuean',
      value: 'NU',
    },
    {
      name: 'Norfolk Islander',
      value: 'NF',
    },
    {
      name: 'North Korean',
      value: 'KP',
    },
    {
      name: 'Macedonian',
      value: 'MK',
    },
    {
      name: 'Norwegian',
      value: 'NO',
    },
    {
      name: 'Omani',
      value: 'OM',
    },
    {
      name: 'Pakistani',
      value: 'PK',
    },
    {
      name: 'Palauan',
      value: 'PW',
    },
    {
      name: 'Palestinian',
      value: 'PS',
    },
    {
      name: 'Panamanian',
      value: 'PA',
    },
    {
      name: 'Papua New Guinean',
      value: 'PG',
    },
    {
      name: 'Paraguayan',
      value: 'PY',
    },
    {
      name: 'Peruvian',
      value: 'PE',
    },
    {
      name: 'Filipino',
      value: 'PH',
    },
    {
      name: 'Pitcairn Islander',
      value: 'PN',
    },
    {
      name: 'Polish',
      value: 'PL',
    },
    {
      name: 'Portuguese',
      value: 'PT',
    },
    {
      name: 'Puerto Rican',
      value: 'PR',
    },
    {
      name: 'Qatari',
      value: 'QA',
    },
    {
      name: 'Romanian',
      value: 'RO',
    },
    {
      name: 'Russian',
      value: 'RU',
    },
    {
      name: 'Rwandan',
      value: 'RW',
    },
    {
      name: 'Saint Barthélemy Islander',
      value: 'BL',
    },
    {
      name: 'Saint Helenian',
      value: 'SH',
    },
    {
      name: 'Kittitian or Nevisian',
      value: 'KN',
    },
    {
      name: 'Saint Lucian',
      value: 'LC',
    },
    {
      name: 'Saint Vincentian',
      value: 'VC',
    },
    {
      name: 'Samoan',
      value: 'WS',
    },
    {
      name: 'Sammarinese',
      value: 'SM',
    },
    {
      name: 'Saudi Arabian',
      value: 'SA',
    },
    {
      name: 'Senegalese',
      value: 'SN',
    },
    {
      name: 'Serbian',
      value: 'RS',
    },
    {
      name: 'Seychellois',
      value: 'SC',
    },
    {
      name: 'Sierra Leonean',
      value: 'SL',
    },
    {
      name: 'Singaporean',
      value: 'SG',
    },
    {
      name: 'Slovak',
      value: 'SK',
    },
    {
      name: 'Slovene',
      value: 'SI',
    },
    {
      name: 'Solomon Islander',
      value: 'SB',
    },
    {
      name: 'Somali',
      value: 'SO',
    },
    {
      name: 'South African',
      value: 'ZA',
    },
    {
      name: 'South Georgian South Sandwich Islander',
      value: 'GS',
    },
    {
      name: 'South Korean',
      value: 'KR',
    },
    {
      name: 'South Sudanese',
      value: 'SS',
    },
    {
      name: 'Spanish',
      value: 'ES',
    },
    {
      name: 'Sri Lankan',
      value: 'LK',
    },
    {
      name: 'Sudanese',
      value: 'SD',
    },
    {
      name: 'Surinamer',
      value: 'SR',
    },
    {
      name: 'Swedish',
      value: 'SE',
    },
    {
      name: 'Swiss',
      value: 'CH',
    },
    {
      name: 'Syrian',
      value: 'SY',
    },
    {
      name: 'Sao Tomean',
      value: 'ST',
    },
    {
      name: 'Taiwanese',
      value: 'TW',
    },
    {
      name: 'Tadzhik',
      value: 'TJ',
    },
    {
      name: 'Tanzanian',
      value: 'TZ',
    },
    {
      name: 'Thai',
      value: 'TH',
    },
    {
      name: 'East Timorese',
      value: 'TL',
    },
    {
      name: 'Togolese',
      value: 'TG',
    },
    {
      name: 'Tokelauan',
      value: 'TK',
    },
    {
      name: 'Tongan',
      value: 'TO',
    },
    {
      name: 'Trinidadian',
      value: 'TT',
    },
    {
      name: 'Tunisian',
      value: 'TN',
    },
    {
      name: 'Turkish',
      value: 'TR',
    },
    {
      name: 'Turkmen',
      value: 'TM',
    },
    {
      name: 'Turks and Caicos Islander',
      value: 'TC',
    },
    {
      name: 'Tuvaluan',
      value: 'TV',
    },
    {
      name: 'Ugandan',
      value: 'UG',
    },
    {
      name: 'Ukrainian',
      value: 'UA',
    },
    {
      name: 'Emirati',
      value: 'AE',
    },
    {
      name: 'British',
      value: 'GB',
    },
    {
      name: 'American',
      value: 'US',
    },
    {
      name: 'Virgin Islander',
      value: 'VI',
    },
    {
      name: 'Uruguayan',
      value: 'UY',
    },
    {
      name: 'Uzbekistani',
      value: 'UZ',
    },
    {
      name: 'Ni-Vanuatu',
      value: 'VU',
    },
    {
      name: 'Vatican',
      value: 'VA',
    },
    {
      name: 'Venezuelan',
      value: 'VE',
    },
    {
      name: 'Vietnamese',
      value: 'VN',
    },
    {
      name: 'Wallis and Futuna Islander',
      value: 'WF',
    },
    {
      name: 'Sahrawi',
      value: 'EH',
    },
    {
      name: 'Yemeni',
      value: 'YE',
    },
    {
      name: 'Zambian',
      value: 'ZM',
    },
    {
      name: 'Zimbabwean',
      value: 'ZW',
    },
    {
      name: 'Ålandish',
      value: 'AX',
    },
  ];
};

export const removeSessionData = () => {
  removeSessionStorage('globalGuestSessionId');
  removeSessionStorage('guestSessionMobile');
  removeSessionStorage('guestSessionTrnNumber');
  removeSessionStorage('guestSessionEmail');
  removeSessionStorage('guestSessionLastName');
  removeSessionStorage('guestSessionFirstName');
  removeSessionStorage('guestSessionVerifyEmail');
};
